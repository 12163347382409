/* stylelint-disable no-descending-specificity */
/* stylelint-disable selector-pseudo-element-colon-notation */
/* stylelint-disable function-url-quotes */
/* stylelint-disable color-hex-length */
/* stylelint-disable selector-id-pattern */
/* stylelint-disable property-no-vendor-prefix */
/* stylelint-disable font-family-name-quotes */
/* stylelint-disable at-rule-no-vendor-prefix */
@import 'normalize.css/normalize.css';
@import './blocks/tooltip.css';
@import 'react-tooltip/dist/react-tooltip.css';


html {
  scroll-behavior: smooth;
  -ms-overflow-style: scrollbar;
  -webkit-font-smoothing: antialiased;
  font-variant-numeric: lining-nums proportional-nums;
  scrollbar-gutter: stable;
}

body {
  font: 400 16px / 1.4 Manrope, sans-serif;
  font-variant-numeric: lining-nums proportional-nums;
  scrollbar-gutter: stable;
}

.modal__body--open {
  overflow: hidden;
}

.ReactModal__Body--open {
  overflow: hidden;
}

.ReactModal__Body--open-safari {
  position: fixed;
  overflow: hidden;
  width: 100vw;
}

b {
  font-weight: 600;
}

input[type='text'],
input[type='submit'] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

#product-main-content__image-portal {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.yt-lite {
  position: relative;
  display: block;
  background-color: #000;
  background-position: center center;
  background-size: cover;
  cursor: pointer;
  contain: content;
}

/* gradient */
.yt-lite::before {
  content: '';
  position: absolute;
  top: 0;
  display: block;
  width: 100%;
  height: 60px;
  padding-bottom: 50px;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAADGCAYAAAAT+OqFAAAAdklEQVQoz42QQQ7AIAgEF/T/D+kbq/RWAlnQyyazA4aoAB4FsBSA/bFjuF1EOL7VbrIrBuusmrt4ZZORfb6ehbWdnRHEIiITaEUKa5EJqUakRSaEYBJSCY2dEstQY7AuxahwXFrvZmWl2rh4JZ07z9dLtesfNj5q0FU3A5ObbwAAAABJRU5ErkJggg==);
  background-position: top;
  background-repeat: repeat-x;
  transition: all 0.2s cubic-bezier(0, 0, 0.2, 1);
}

/* responsive iframe with a 16:9 aspect ratio
    thanks https://css-tricks.com/responsive-iframes/
*/
.yt-lite::after {
  content: '';
  display: block;
  padding-bottom: calc(100% / (16 / 9));
}

.yt-lite > iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* play button */
.yt-lite > .lty-playbtn {
  z-index: 1;
  width: 70px;
  height: 46px;
  border-radius: 14%; /* TODO: Consider replacing this with YT's actual svg. Eh. */
  background-color: #212121;
  opacity: 0.8;
  transition: all 0.2s cubic-bezier(0, 0, 0.2, 1);
}

.yt-lite:hover > .lty-playbtn {
  background-color: #f00;
  opacity: 1;
}

/* play button triangle */
.yt-lite > .lty-playbtn:before {
  content: '';
  border-width: 11px 0 11px 19px;
  border-style: solid;
  border-color: transparent transparent transparent #fff;
}

.yt-lite > .lty-playbtn,
.yt-lite > .lty-playbtn:before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
}

/* Post-click styles */
.yt-lite.lyt-activated {
  cursor: unset;
}

.yt-lite.lyt-activated::before,
.yt-lite.lyt-activated > .lty-playbtn {
  opacity: 0;
  pointer-events: none;
}


@media (max-width: 1279px) {
  .retailcrm-consultant-app.consultant-app.consultant-app_new.consultant-window_right-bottom {
    bottom: 64px!important;
    right: 16px!important;
  }

  .consultant-tooltip.consultant-tooltip_right-bottom {
    display: none!important;
  }

  .consultant-widget-wrap.consultant-widget-wrap_right-bottom {
    width: 40px!important;
    height: 40px!important;
  }

  .consultant-icon {
    width: 40px!important;
    height: 40px!important;
  }

  .consultant-icon__img {
    width: 18px!important;
    height: 20px!important;
    top: 10px!important;
  }
}
