.tooltip {
  cursor: default;

  &._nowrap {
    white-space: nowrap;

    .tooltip__inner {
      padding: 8px 16px;
    }
  }

  &__inner {
    background: #222122;
    padding: 8px;
    max-width: 330px;
    width: 100%;
    box-sizing: border-box;

    &:before {
      content: '';
      display: block;
      border: 6px solid transparent;
      border-bottom-color: #222122;
      position: absolute;
      bottom: 100%;
      left: 50%;
      margin-left: -6px;
    }
  }

  &__text {
    font: 400 12px / 22px Manrope, sans-serif;
    color: #ffffff;
    text-align: center;
    text-transform: none;
  }
}
