@import '../src/shared/styles/core.css';

.content-grid {
  display: grid;
  grid-template-columns: [full-width-start] 16px [content-start] minmax(0, 1fr) [content-end] 16px [full-width-end];
  box-sizing: border-box;
}

.content-grid > * {
  grid-column: content;
}

.content-grid > .full-width {
  grid-column: full-width;
}

@media (--screen-xs) {
  .content-grid {
    grid-template-columns: [full-width-start] 32px [content-start] minmax(0, 1fr) [content-end] 32px [full-width-end];
  }
}

@media (--screen-lg) {
  .content-grid {
    grid-template-columns: [full-width-start] minmax(48px, 1fr) [content-start] min(calc(100% - 96px), 1520px) [content-end] minmax(
        48px,
        1fr
      ) [full-width-end];
  }
}
